import React, { useState } from 'react'
import {Link} from 'components/link'
import {ReactComponent as Petals} from 'images/icons/Seeds-Tall.svg'

import './style.css'

export const ReadMoreButton = ({link, fontColor, symbolColor, className, textClass, showSymbol, text}) => {
  let [hovered, setHovered] = useState(false)
  let classes=''
  let _textClass=''
  let _text = text?text:'Read more'
  
  if(className){
    classes=className
  }
  if(textClass){
    _textClass = textClass
  }
  if(!showSymbol){
    showSymbol=false
  }
 
  return (
    <div className={`topics-read-more__container ${classes}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
    >
      <Link
        to={link.url}
        title={link.title}
        target={link.target}
        style={{color: fontColor}}
        className='topics-read-more__text'
      >
        {(hovered || showSymbol) &&
         <div className='topics-read-more__background'>
           <Petals style={{color: symbolColor?symbolColor:'#AAC939'}} className='fill-current topics-read-more__petals' />
         </div>
        }
        <span className={`${_textClass}`}>{_text}</span>
      </Link>
     
    </div>
  )
}