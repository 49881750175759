import React from 'react';
import {graphql} from 'gatsby';
import Layout from 'components/layout'
import {TopicsArchive} from 'components/topics/archive'
export default  ({data, ...props}) => {
  return (
    <Layout
      articleId={`${data.wordpressPage.type}-${data.wordpressPage.wordpress_id}`}
      articleClassName={`${data.wordpressPage.classes}`}
      context = {props.pageContext}
      location = {props.location}
    >
      <div className="container mx-auto mt-40">
        <TopicsArchive {...data.wordpressPage}/>
      </div>
    </Layout>
  )
}
  



export const TopicsArchiveQuery = graphql `
query AllTopicsPageById($id: String!){
  wordpressPage(id:{eq:$id}){
    title
    path
    excerpt
    type
    wordpress_id
    classes
  }
}
`
