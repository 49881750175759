import React from 'react'
import Styles from './tallcard.module.css'
import Img from 'gatsby-image'
import {ReadMoreButton} from '../readMoreButton'
import MediaQuery from 'react-responsive'


export const DesktopTallTopicCard = (props) => {
  const [hover, setHover] = React.useState(false)
  return (
    <div
      className={Styles.card}
      style={{backgroundColor:props.color}}
      onMouseEnter={()=>setHover(true)}
      onMouseLeave={()=>setHover(false)}

    >
      <div className={Styles.header}>
        {props.icon && props.icon.localFile &&
         <Img fixed={props.icon.localFile.childImageSharp.fixed}/>
        }

        <p className={Styles.title} dangerouslySetInnerHTML={{__html:props.title}}/>

        {hover && 
        <div className={Styles.content}>
          <div dangerouslySetInnerHTML={{__html:props.excerpt}}/>
          <ReadMoreButton
            fontColor='#fff'
            symbolColor="#fff"
            textClass='text-xl'
            fontColor='#fff'
            symbolColor="#fff"
            showSymbol={false}
            className="mt-5"
            link={{
              url: props.path,
              title: props.title
            }}
          />
        </div>
        }
      </div>
      {!hover && 
      <div className={Styles.imageWrapper}>
    <Img fluid={props.featured_media.localFile.childImageSharp.fluid} style={{height:"100%"}}/>
       </div>
      }
    </div>
  )
}
export const MobileTallTopicCard = (props) => {
  const [hover, setHover] = React.useState(false)
  return (
    <div
      className={Styles.card}
      style={{backgroundColor:props.color}}
      onMouseEnter={()=>setHover(true)}
      onMouseLeave={()=>setHover(false)}

    >
      <div className={Styles.header}>
        {props.icon && props.icon.localFile &&
         <Img fixed={props.icon.localFile.childImageSharp.fixed}/>
        }
        <p className={Styles.title} dangerouslySetInnerHTML={{__html:props.title}}/>
        <div className={Styles.content}>
          <div dangerouslySetInnerHTML={{__html:props.excerpt}}/>
          <ReadMoreButton
            textClass='text-xl'
            fontColor='#fff'
            symbolColor="#fff"
            showSymbol={true}
            className="mt-5"
            link={{
              url: props.path,
              title: props.title
            }}
          />
        </div>
      </div>
    </div>
  )
}
export const TallTopicCard = (props) => {
  return (
    <>
    <MediaQuery minDeviceWidth={1224}>
      <DesktopTallTopicCard {...props}/>
    </MediaQuery>
    <MediaQuery maxDeviceWidth={1223}>
      <MobileTallTopicCard {...props}/>
    </MediaQuery>
    </>
  )
}
