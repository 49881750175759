import React from 'react';
import {withAllTopics} from './hooks/withalltopics'
import {TallTopicCard} from './cards/tall'
import Styles from './archive.module.css'
import {BgPattern} from 'components/backgrounds'
import PatternStyles from 'components/backgrounds/patterns.module.css'
export const TopicsArchive = (props) => {
  const topics = withAllTopics()
  return (
    <div className={Styles.grid}>
      <BgPattern className={`${PatternStyles.pattern2} ${Styles.background}`}/>
      <div className={Styles.item}>
        <div className={Styles.titleCard}>
          <h1 dangerouslySetInnerHTML={{__html:props.title}}/>
          <div dangerouslySetInnerHTML={{__html:props.excerpt}}/>
        </div>
        
      </div>
    {topics && topics.length>0 && topics.map(topic => (
      <div className={Styles.item} key={topic.id}>
        <TallTopicCard {...topic}/>
      </div>
    ))}
    </div>
  )
}
